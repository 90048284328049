<script>
    import { createEventDispatcher } from 'svelte';

    export let currentFilter = 'all'; // Filter state passed from parent

    const dispatch = createEventDispatcher(); // Create the event dispatcher

    const setFilter = (filter) => {
        currentFilter = filter; // Update the filter
        dispatch('filterChange', { filter }); // Dispatch the 'filterChange' event
    };
</script>

<div class="filter-buttons">
    <button 
        class:active={currentFilter === 'all'} 
        on:click={() => setFilter('all')}>
        Visi
    </button>
    
    <button 
        class:active={currentFilter === 'waiting_answer'} 
        on:click={() => setFilter('waiting_answer')}>
        Laukiama
    </button>
    <button 
        class:active={currentFilter === 'processed'} 
        on:click={() => setFilter('processed')}>
        Atsakyti
    </button>

</div>

<style>
    .filter-buttons {
        display: flex;
        gap: 1rem;
        height: 60px;
        justify-content: space-between;
    }

    button { 
        border-radius: 8px;
        background-color: white;
        color: #000000;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        width:100%;
    }

    button.active {
        background-color: #14695b;
        color: white;
    }

    button:hover {
        background-color: #14695b;
        color: white;
    }
</style>
