<script>
    let daysOfWeek = [
      { value: 'monday', label: 'Pirmadienis' },
      { value: 'tuesday', label: 'Antradienis' },
      { value: 'wednesday', label: 'Trečiadienis' },
      { value: 'thursday', label: 'Ketvirtadienis' },
      { value: 'friday', label: 'Penktadienis' },
      { value: 'saturday', label: 'Šeštadienis' },
      { value: 'sunday', label: 'Sekmadienis' },
    ];
    
    let startTime = '';
    let endTime = '';
    let selectedDay = '';

    // Retrieve user from localStorage when the component is created
    const submitForm = async () => {
        const token = localStorage.getItem('jwtToken')
        console.log("This is the token:)")
        console.log(token)
        
        const formData = {
            day_of_week: selectedDay,
            start_time: startTime,
            end_time: endTime,
        };
    
        try {
            const response = await fetch('https://www.tutory.app/studentplayground/add-timeslot/', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
            });
    
            if (!response.ok) {
                throw new Error('Something went wrong with the request');
            }
    
            console.log('Form data submitted successfully:', await response.json());
            window.location.reload();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        };
  </script>
  
  <style>
    .form-container {
     
        width:100%;
    }
  
    .form-group {
      margin-bottom: 16px;
      display:flex;
      flex-direction: column;
      width:40%;
    }
    .form-group2{
      margin-bottom: 16px;
      display:flex;
      flex-direction: column;
      
    }
  
    button {
      background-color: #fab45a;
      color: #000;
      padding: 12px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  
    button:hover {
      background-color: #927043;
    }
    input{
        
        border-radius: 6px;
        border: 1px solid #14695b;
    }
    select {
      width: 100%;
      padding: 8px;
      border-radius: 6px;
      border: 1px solid #14695b;
    }
    .time-container{
        display:flex;
        flex-direction:row;
        justify-content:space-between;
    }
    span{
        margin-bottom:5px;
        font-weight: bold;
    }
    button span {
        font-weight: bolder;
        font-size:larger;
    }

    button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
        gap: 10px;
    
        width: 100%;
        height: 45px;
    
        /* Purple background */
        background-color: white;
    
        /* Black border */
        border: 2px solid #000000;
    
        /* Box shadow */
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    
        /* Rounded corners */
        border-radius: 8px;
    
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    
        /* Remove default button styling */
        outline: none;
        cursor: pointer;
  
        /* Remove the default button background on focus */
        background-image: none;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    /* Button hover effect */
    button:hover {
        background-color: #14695b;
        color:white;
    }
  </style>
  
  <div class="form-container">
    <h2>Pridėti laisvą laiką</h2>
    <div class='time-container'>
        <div class="form-group">
        <span>Nuo</span>
        <input type="time" bind:value={startTime} />
        </div>
        
        <div class="form-group">
        <span>Iki</span>
        <input type="time" bind:value={endTime} />
        </div>
    </div>
    
    <div class="form-group2">
      <span>Savaitės diena</span>
      <select bind:value={selectedDay}>
        <option value="" disabled selected>Rinktis...</option>
        {#each daysOfWeek as day}
          <option value={day.value}>{day.label}</option>
        {/each}
      </select>
    </div>
    
    <button on:click={submitForm}><span>Pridėti laiką</span></button>
  </div>
  