<script>
  // Accept a tuple (array) with [time, date]
  export let time;
  export let date;
</script>

<style>
  .date-time-container {
    display: flex;
    align-items: center;
  }

  .time, .divider, .date {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.5rem; /* Ensure same size for all */
    font-weight: bold;  /* Set same weight for uniform look */
  }

  .divider {
    margin: 0 0.5rem; /* Adjust margin to space out elements */
  }

  .date {
    color: var(--date-color);
  }
</style>

<div class="date-time-container">
  <span class="time">{time}</span>
  <span class="divider">|</span>
  <span class="date">{@html date}</span>
</div>
