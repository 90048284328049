<!-- src/App.svelte -->
<script>
  import Logo from './LogoComponent.svelte';
  import IconButton from './ButtonComponent.svelte';
  import { onMount } from 'svelte';

  let isUserTeacher = null;
  let errorMessage = '';
  let isUserSuper = null;
  
  const buttons = [
    { icon: '/static/icons/computer.png', label: 'REGISTRUOTIS Į PAMOKĄ',link:'/registration-for-class' },
    { icon: '/static/icons/calendar.png', label: 'TVARKARAŠTIS',link:'/schedule' },
    { icon: '/static/icons/clipboard.png', label: 'PAMOKŲ PAKEITIMAI',link:'/reschedule-requests' },
    { icon: '/static/icons/books.png', label: 'MANO PROFILIS',link:'/settingsAndTimeslots' }
  ];
  // This function fetches the teacher status from the backend API
  async function checkIfUserIsTeacher() {
    console.log("ive at least made it this far")
    try {
        // Retrieve JWT token from localStorage/sessionStorage/cookie (depending on how you store it)
        const token = localStorage.getItem('jwtToken'); 
        console.log("THIS IS THE TOKEN")
        console.log(token)
        if (!token) {
            throw new Error('JWT token not found. Please log in.');
        }

        const response = await fetch('https://www.tutory.app/studentplayground/isUserTeacher/', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            // Handle error response from the server
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to check teacher status');
        }

        const data = await response.json();
        isUserTeacher = data.isUserTeacher;
        console.log("this is the value of teacher :)")
        console.log(isUserTeacher)
        if (isUserTeacher) {
          buttons[0] = { icon: '/static/icons/clipboard.png', label: 'PAMOKŲ PASIŪLYMAI',link:'/teacher_requests' };
        }

        console.log("now checking for superuser")
        const response2 = await fetch('https://www.tutory.app/studentplayground/isSuperUser/', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response2.ok) {
            // Handle error response from the server
            const errorData = await response2.json();
            throw new Error(errorData.error || 'Failed to check teacher status');
        }
        
        const data2 = await response2.json();
        isUserSuper = data2.isUserSuper;
        console.log("this is the value of da SUPAH USAH :)")
        console.log(isUserSuper)
        if (isUserSuper) {
          buttons[1] = { icon: '/static/icons/clipboard.png', label: 'ADMIN PAGE',link:'/admin' };
        }
    } catch (error) {
        errorMessage = error.message;
    }
    
  }

    // Fetch teacher status on component mount
  onMount(async () => {
    console.log("THIS IS THE VERY FIRST STEP");
    await checkIfUserIsTeacher(); // Await this if it's asynchronous
  
  });





</script>
<div class ="content">
  <img src="/static/images/Tutory Logo Black 1.png" alt="TUTORY Logo" class="logo" style="max-width: 200px;">
  <section class="button-group">
    {#each buttons as button}
      <IconButton icon={button.icon} label={button.label} link={button.link}/>
    {/each}
  </section>
</div>

<style>
  .content{
        /* Frame 1214136027 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 28px;
    height: 548px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

  }
  .logo{
    max-width:250px;

  }
  .button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  @media (min-aspect-ratio: 4/3) {
  .button-group {
    width: 450px;
    }
  }
</style>
