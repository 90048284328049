<script>
    export let label = "Click me";
    export let link = "#"; // Accept a link as a prop, default to '#'

    // Function to handle navigation
    function navigateToLink() {
        window.location.href = link; // This navigates to the given link
    }
</script>

<button on:click={navigateToLink}>
    <span>{label}</span>
</button>

<style>
    /* Button styling */
    button span {
        font-weight: bolder;
        font-size:larger;
    }

    button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
        gap: 10px;
    
        width: 100%;
        height: 60px;
    
        /* Purple background */
        background-color: #faad4a;
    
        /* Black border */
        border: 2px solid #000000;
    
        /* Box shadow */
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    
        /* Rounded corners */
        border-radius: 8px;
    
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    
        /* Remove default button styling */
        outline: none;
        cursor: pointer;
  
        /* Remove the default button background on focus */
        background-image: none;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    /* Button hover effect */
    button:hover {
        background-color: #14695b;
        color:white;
    }

    /* Text inside the button */
    span {
        font-size: 1rem;
        font-weight: bold;
        
    }
</style>
