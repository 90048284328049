<script>
    export let value = '';  // This is the bound value
    export let placeholder = ''; // Placeholder text
    export let label = '';
  </script>
  
  <div class="text-field">
    <label for='text-field'>{label}</label>
    <textarea 
      bind:value={value}
      placeholder={placeholder}
      rows="4" 
    ></textarea>
  </div>

<style>
    label{
        margin-bottom: 10px;
        margin-top: 20px;
        font-size: 1.17em;
        font-weight: bold;

    }
    div{
        width:100%;
    }
    textarea {
        background-color:#eeebdb;
        width: 100%;
        height: 100px;
        border: 1px solid #14695b;
        
        /* Box shadow */
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        
        /* Rounded corners */
        border-radius: 8px;

        /* Adjust the padding for better text visibility */
        padding: 10px;
        
        /* Optional: Set a font for better readability */
        font-family: Arial, sans-serif;
        font-size: 16px;
        resize: vertical; /* Allows the user to resize the textarea vertically */
    }

</style>
