<script>
    import Checkbox from './CheckBoxForInvoice.svelte';
    export let selectedMonths= [];

    let subjects = [
        { name: 'Sausis',value:1, selected: false},
        { name: 'Vasaris',value:2, selected: false },
        { name: 'Kovas', value:3,selected: false},
        { name: 'Balandis',value:4, selected: false },
        { name: 'Gegužė', value:5,selected: false },
        { name: 'Birželis', value:6,selected: false },
        { name: 'Liepa', value:7,selected: false},
        { name: 'Rupjūtis', value:8,selected: false },
        { name: 'Rugsėjis',value:9, selected: false },
        { name: 'Spalis',value:10, selected: false },
        { name: 'Lapkritis',value:11, selected: false },
        { name: 'Gruodis',value:12, selected: false }
    ];

    const toggleSubject = (index) => {
        subjects[index].selected = !subjects[index].selected;
        selectedMonths = subjects.filter(subject => subject.selected).map(subject => subject.value);
    };
</script>

<div class="checkbox-area">
    {#each subjects as subject, i}
        <Checkbox 
            
            subject={subject.name} 
            isSelected={subject.selected} 
            onToggle={() => toggleSubject(i)} 
        />
    {/each}
</div>

<style>
    .checkbox-area {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width:100%;
    }
</style>
