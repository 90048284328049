<script>
    export let value = '';
    export let id = '';
    export let onRemove = null;

    const handleChange = (event) => {
        value = event.target.value;
    };
</script>

<div class="datetime-picker">
    
    <h3>Pamokos data ir laikas</h3>
    <div class = 'topPart'>
        <input 
            type="datetime-local" 
            id={id} 
            bind:value={value} 
            on:input={handleChange} 
        />
        <button type="button" class ="removeButton" on:click={onRemove}>
            <img src='/static/icons/trintiIcon.png' alt="" class="icon" />
            Trinti
        </button>
    </div>
</div>

<style>

    .topPart{
        display:flex;
        justify-content: space-between;
    }
    .datetime-picker {
        width: 100%;
        display: flex;
        margin-bottom: 1rem;
        flex-direction: column;
    }

    input[type="datetime-local"] {
        background-color: #eeebdb;
        padding: 0.5rem;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        margin-right: 10px;
    }

    button {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        cursor: pointer;
        background-color: #f0a298;
        color: white;
        border: none;
        border-radius: 4px;
    }

    button:hover {
        background-color: #927043;
        
    }
    .removeButton{
        /* Button */
        /* Auto layout */
        display: flex;
        justify-content: center;
        align-items: center;
        height:40px;
        background-color: #fab45a;
        transition: background-color 0.3s ease, color 0.3s ease;
        color:black;
        
    }
    .removeButton:hover{
        background-color: #c98f43;
    
    }
</style>
