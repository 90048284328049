<script>
  let message = '';
  let loading = false;
  let errorMessage = '';
  let selectedTeacher = ''; // Stores selected teacher's name (for UI)
  let googleCalendarId = ''; // Stores selected Google Calendar ID (for request)
  let onlyRefreshSelected = false; // Stores checkbox state
  let teachers = []; // Stores teachers with their names and Google Calendar IDs

  async function fetchTeachers() {
      const url = 'https://www.tutory.app/studentplayground/api/get_google_calendar_ids/';
      const token = localStorage.getItem('jwtToken');
      if (!token) {
          errorMessage = 'JWT token not found. Please log in.';
          return;
      }

      try {
          const response = await fetch(url, {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
          });

          if (!response.ok) {
              throw new Error(`Error: ${response.status}`);
          }

          const data = await response.json();
          teachers = data.teachers; // Assuming backend returns { "teachers": [...] }
      } catch (error) {
          errorMessage = `Failed to fetch teachers: ${error.message}`;
      }
  }

  async function refreshDatabase() {
      const url = 'https://www.tutory.app/studentplayground/refresh/';
      const token = localStorage.getItem('jwtToken');
      if (!token) {
          errorMessage = 'JWT token not found. Please log in.';
          return;
      }

      loading = true;
      message = '';
      errorMessage = '';

      try {
          const response = await fetch(url, {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  only_refresh_selected: onlyRefreshSelected,
                  google_calendar_id: onlyRefreshSelected ? googleCalendarId : null
              }),
          });

          if (!response.ok) {
              throw new Error(`Error: ${response.status}`);
          }

          const data = await response.json();
          message = data.message;
      } catch (error) {
          errorMessage = `Failed to update: ${error.message}`;
      } finally {
          loading = false;
      }
  }

  // Fetch teachers on component mount
  fetchTeachers();
</script>

<!-- HTML/Markup -->
<div>
  <label>
      <input type="checkbox" bind:checked={onlyRefreshSelected} />
      Refresh only for a specific teacher
  </label>

  {#if onlyRefreshSelected}
      <select bind:value={selectedTeacher} on:change="{(e) => {
          const selected = teachers.find(t => t.name === e.target.value);
          googleCalendarId = selected ? selected.googleCalendarId : ''; // Store Google Calendar ID for request
      }}" disabled={loading}>
          <option value="">Select a Teacher</option>
          {#each teachers as teacher}
              <option value={teacher.name}>{teacher.name}</option>
          {/each}
      </select>
  {/if}

  <button on:click={refreshDatabase} disabled={loading || (onlyRefreshSelected && !googleCalendarId)}>
      {loading ? 'Updating...' : 'Refresh Database'}
  </button>

  <!-- Display success message -->
  {#if message}
      <p>{message}</p>
  {/if}

  <!-- Display error message -->
  {#if errorMessage}
      <p style="color: red;">{errorMessage}</p>
  {/if}
</div>

<style>
  button {
      padding: 10px 20px;
      background-color: #4CAF50;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
  }
  button:disabled {
      background-color: #cccccc;
  }
  p {
      margin-top: 10px;
  }
  select {
      margin-left: 10px;
      padding: 5px;
  }
</style>
