<script>
  export let infoText = '';  // Customizable text for the tooltip
  let showTooltip = false;   // State variable to control visibility
  export let className = '';  // Accept a className prop

  function toggleTooltip() {
    showTooltip = !showTooltip;
  }

  // Handle keypress for accessibility (trigger tooltip on Enter or Space)
  function handleKeydown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleTooltip();
    }
  }
</script>

<style>
  .tooltip-container {
    display: inline-flex; /* Keep icon inline with text */
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    margin-left: 5px; /* Adds spacing between the text and the icon */
    font-size: 0.9em; /* Adjust the icon size to be smaller */
}

  .tooltip-icon {
    font-size: x-small;
    color: #007BFF;
    border: 1px solid #007BFF;
    border-radius: 100%;
    padding: 1px 5px;
    font-weight: bold;
    margin-left: 5px;
  }

  .tooltip-box {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: -160%;
    left: 55%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s;
    white-space: normal; /* Allows wrapping of content */
    word-wrap: break-word; /* Ensures long words break */
    width:500px;
  }

  .tooltip-box.visible {
    visibility: visible;
    opacity: 1;
  }

  .tooltip-box::after {
    content: '';
    position: absolute;
    top: 100%;  /* Arrow pointing down */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;
    color: #fff;
    cursor: default; /* No pointer interaction */
  }
  /* Mobile screen adjustments */
  @media (max-width: 600px) {
    .tooltip-box {
      position: fixed;
      top:160px;
      width:200px;
    }

    .tooltip-container {
      display: block;
      text-align: center;
    }
    button{
      padding:0px;
    }
  }
</style>

<button class="tooltip-container {className}" on:click={() => showTooltip = !showTooltip}>
  <span class="tooltip-icon">i</span>
  <div class="tooltip-box {showTooltip ? 'visible' : ''}">
    {@html infoText}
    <span class="close-icon">✕</span>

  </div>
</button>
