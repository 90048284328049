<script>
    // Accepting the prop `data` which contains the array of matched teachers
    export let data = [];

    // Helper function to convert day_of_week number to day name
    const dayOfWeekToName = (day) => {
        const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        return days[day - 1];
    };
</script>

<style>
    .display-container {
        border: 1px solid #ddd;
        padding: 1em;
        margin: 1em 0;
        border-radius: 8px;
        background-color: #f9f9f9;
    }

    .title {
        font-weight: bold;
        margin-bottom: 0.5em;
    }

    .matched-slot, .conflict-slot {
        margin-bottom: 0.5em;
    }

    .matched-slot div, .conflict-slot div {
        margin-left: 1em;
    }

    .section-title {
        font-weight: bold;
        margin-top: 1em;
    }

    .conflict {
        color: red;
    }

    .teacher-container {
        margin-bottom: 2em;
    }

    .no-data {
        font-style: italic;
        color: #888;
    }
</style>

{#if data.length > 0}
    {#each data as teacher, index}
    <div class="teacher-container">
        <div class="display-container">
            <!-- Teacher information -->
            <p class="title">Teacher {index + 1}: {teacher.teacher_name}</p>
            <p>Subject: {teacher.subject}</p>
            <p>Grade: {teacher.grade}</p>

            <!-- Matched Time Slots -->
            {#if teacher.matched_timeslots?.length}
                <div>
                    <p class="section-title">Matched Time Slots:</p>
                    {#each teacher.matched_timeslots as slot}
                        <div class="matched-slot">
                            <p>Day: {dayOfWeekToName(slot.day_of_week)}</p>
                            <div>Start: {slot.start_time}</div>
                            <div>End: {slot.end_time}</div>
                        </div>
                    {/each}
                </div>
            {:else}
                <p class="no-data">No matched time slots.</p>
            {/if}

            <!-- Conflicting Classes -->
            {#if teacher.conflicting_classes?.length}
                <div>
                    <p class="section-title conflict">Conflicting Classes:</p>
                    {#each teacher.conflicting_classes as conflict}
                        <div class="conflict-slot">
                            <p>Class Name: {conflict.class_name}</p>
                            <p>Day: {dayOfWeekToName(conflict.day_of_week)}</p>
                            <div>Start: {conflict.class_start_time}</div>
                            <div>End: {conflict.class_end_time}</div>
                        </div>
                    {/each}
                </div>
            {:else}
                <p class="no-data">No conflicting classes.</p>
            {/if}
        </div>
    </div>
    {/each}
{:else}
    <p>No teachers found.</p>
{/if}
