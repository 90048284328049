<script>
  import { push } from 'svelte-spa-router'; // Import the push function

  export let icon;
  export let label;
  export let link = '#'; // This will be the route for navigation
  export let cl = '';

  // Function to handle navigation using svelte-spa-router
  function navigate(event) {
      event.preventDefault(); // Prevent the default href behavior
      push(link); // Use svelte-spa-router's push function to navigate
  }
</script>

<a href={link} class={cl} on:click={navigate}> <!-- Keep href for a11y but use push for SPA routing -->
  <img src={icon} alt="" class="icon" />
  <span>{label}</span>
</a>

  
  <style>
    a {
      /* Single choice */
      box-sizing: border-box;
  
      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px 20px;
      gap: 10px;
  
      width: 100%;
      height: 96px;
  
      /* Purple background */
      background: #fab45a;
  
      /* Black border */
      border: 1px solid #000000;
  
      /* Box shadow */
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  
      /* Rounded corners */
      border-radius: 8px;
  
      /* Inside auto layout */
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
  
      /* Ensure the button doesn't have default browser styling */
      outline: none;
      cursor: pointer;

      text-decoration: none;
    }
    a:hover{
      background-color:#927043;
    }
  
    .icon {
      width: 60px; /* Adjust this size as needed */
      height: 60px; /* Adjust this size as needed */
    }
  
    span {
      font-size: 1rem;
      font-weight: bold;
      color: #333;
    }
  </style>
  