<script>
    export let params;  // `params` will be passed as a prop by `svelte-spa-router`
    import { onMount } from 'svelte';
    import { push } from 'svelte-spa-router';
    import ClassName from './ClassNameComponent.svelte';
    import TeacherName from './TeacherNameComponent.svelte'
    import TimeShowerComponent from './TimeShowerComponent.svelte';
    import TimeShower from './TimeShowerComponent.svelte';
    import SendEmailLink from './RecieveEmailComponent.svelte';
    import ButtonToJoinClass from './ButtonToJoinClassComponent.svelte'
    import RescheduleButton from './ButtonToRescheduleComponent.svelte'
    import DateTimePicker from './DateTimePickerForClassChangeComponent.svelte'
    import CancelReason from './CancelReasonComponentsvelte.svelte'

    let classId = params.id;
    let error = null;
    let classinfo ="mothing";
    let timePart = '';
    let formattedDate ='';


    const fetchRescheduleRequest = async (classId) => {
        const token = localStorage.getItem('jwtToken'); // Retrieve token from localStorage
        console.log("THis is the JWT TOKEN")
        console.log(token)
        if (!token) {
            console.error('No JWT token found. User might not be logged in.');
            return;
        }

        try {
            // Making a request to reschedule endpoint with the classId parameter
            const response = await fetch(`https://www.tutory.app/studentplayground/reschedule/${classId}/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,  // Send the token for authentication
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Reschedule Request Data:', data);
                classinfo = data.classinfo
                return data.classinfo;  // Return the classinfo data
            } else {
                console.error('Failed to fetch reschedule request:'+error, response.statusText);
            }
        } catch (error) {
            console.error("Error during reschedule request fetch:", error);
        }
    };
    const formatDateTime = (datetime) => {
        console.log("THis is dateimte")
        console.log(datetime)
        let parts = datetime.split(" ");
        let datePart = parts[0];
        let timePart = parts[1];
        let dayPart = parts[2];

        let formattedDate = `${dayPart}<br>${datePart}`;
            return { timePart, formattedDate };
    };
    onMount(() => {
        fetchRescheduleRequest(classId);
        
    });
    $: if (classinfo && classinfo.time) {
        let { timePart: tp, formattedDate: fd } = formatDateTime(classinfo.time);
        timePart = tp;
        formattedDate = fd;
    }


    async function handleCancelSubmit(event) {
        const token = localStorage.getItem('jwtToken');
        const reason = event.detail.reason;
        console.log("THIs is the reason why")
        console.log(reason)
        console.log("This is the token, pls be here")
        console.log(token)
        try {
            const response = await fetch(`https://www.tutory.app/studentplayground/cancelEvent/${classId}/`, {
                method: 'POST',
                headers: {
                   
                    'Authorization': `Bearer ${token}`,  // Send the token for authentication
                    'Content-Type': 'application/json'
                
                },
                body: JSON.stringify({ reason: reason })
            });

            if (response.ok) {
                push('/change-class-success')
            } else {
                alert(`Error: ${response.statusText}`);
            }
        } catch (error) {
            alert(`Error: ${error.message}`);
        }
    }

</script>
<div class="top-bar">
        
    <a href='#/schedule' class="exit-button">
    <img src='/static/icons/backButtonIcon.png' alt="Exit" class="icon" />
    </a>
    <div class="label">Keisti Rezervaciją</div>
    <div class="filler"></div>
</div>

<div class='classcontainer'>
    <ClassName name={classinfo.name} />
    <TeacherName teacher={classinfo.teacher}/>
    <TimeShower time={timePart} date={formattedDate} />
    <CancelReason on:submit={handleCancelSubmit}/>
    

</div>

{#if error}
<p>Error: {error}</p>
{/if}
<style>
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: transparent;
    border-bottom: 1px solid #000000;
    margin-bottom: 40px;
  
  }
  .filler {
    width:50px;
  }

  .label {
    font-size: x-large;
    color:#14695b;
    
  }

  .exit-button {
    max-width: 50px;
  }

  .icon {
    width: 50px;
    height: 50px;
  }
p {
    font-size: 1.2em;
}
.classcontainer{
    padding: 15px;
    background-color: #eeebdb;
    display: flex;
    flex-direction: column;
    margin-bottom:15px;
    gap: 10px;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 8px;
    flex: none;
    color:black;
    align-items: flex-start;

  }
</style>
