<script>
    export let subject = '';
    export let isSelected = false;
    export let onToggle;
</script>

<div class="checkbox-group" class:selected={isSelected}>
    <div class='leftSide'>
        <label for={subject}>{subject}</label>
    </div>
    <input 
        type="checkbox" 
        id={subject} 
        checked={isSelected} 
        on:change={onToggle} 
    />
</div>

<style>
    .checkbox-group {
        height: 45px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;
        gap: 10px;

        background: #eeebdb;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        transition: background-color 0.3s ease, color 0.3s ease; /* Add color transition */
    }

    .checkbox-group.selected {
        background-color: #14695B; /* Background color when selected */
    }

    .leftSide {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .leftSide label {
        font-size: 1rem;
        color: #333;
        transition: color 0.3s ease; /* Smooth transition for text color */
    }

    .checkbox-group.selected .leftSide label {
        color: #fff; /* Change text color to white */
    }

    input[type="checkbox"] {
        width: 24px;
        height: 24px;
        border-radius: 6px;
        border: 2px solid #333;
        background-color: white;
        appearance: none;
        outline: none;
        cursor: pointer;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        transition: background-color 0.3s ease;
    }

    input[type="checkbox"]:checked {
        background-color: #14695B;
        border: none;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"%3E%3Cpolyline points="20 6 9 17 4 12"/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: center;

    }
</style>
