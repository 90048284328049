<script>
    import { onMount } from 'svelte';
    import { writable } from 'svelte/store';
    import RatingDisplayComponent from './RatingDisplayComponent.svelte';
    import TextDisplayForAdminComments from './TextDisplayForAdminComments.svelte';
    import ClassNameComponent from './ClassNameComponent.svelte';
    import TimeShower from './TimeShowerComponent.svelte';
    import CameOnTimeDisplay from './CameOnTimeDisplay.svelte';
    import TeacherNameComponent from './TeacherNameComponent.svelte';

    // Stores for form data
    let selectedStudent = writable("");
    let students = writable([]);
    let comments = writable(null);

    const formatDateTime = (datetime) => {
        let parts = datetime.split(" ");
        let datePart = parts[0];
        let timePart = parts[1];
        let dayPart = parts[2];

        let formattedDate = `${dayPart}<br>${datePart}`;
            return { timePart, formattedDate };
    };

    // Fetch list of students when component mounts
    onMount(async () => {
        try {
            const token = localStorage.getItem('jwtToken');
            const response = await fetch('https://www.tutory.app/studentplayground/get_all_students/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                students.set(data.students);  // Set students data with first_name and last_name
            } else {
                console.error("Failed to load students");
            }
        } catch (error) {
            console.error("Error fetching students:", error);
        }
    });

    // Function to fetch comments for the selected student
    async function getComments() {
        if (!$selectedStudent) return;

        const payload = { student: $selectedStudent };  // Use selected student's ID

        try {
            const token = localStorage.getItem('jwtToken');
            const response = await fetch('https://www.tutory.app/studentplayground/get_comments_admin', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                comments.set(data.comments); // Set comments data
            } else {
                console.error("Error fetching comments");
                comments.set([{ error: "No comments found for this student" }]);
            }
        } catch (error) {
            console.error("Request failed:", error);
            comments.set([{ error: "Request failed" }]);
        }
    }
</script>

<!-- UI for selecting the student and fetching comments -->
<div class="container">
    <h2>Student Comments Viewer</h2>

    <!-- Dropdown for selecting a student -->
    <div class="form-group">
        <label for="student">Select Student:</label>
        <select id="student" bind:value={$selectedStudent}>
            <option value="" disabled selected>Select a student</option>
            {#each $students as student}
                <option value={student.id}>{student.first_name} {student.last_name}</option> <!-- Display names -->
            {/each}
        </select>
    </div>

    <!-- Fetch Comments Button -->
    <button on:click={getComments}>Get Comments</button>



    <!-- Display Comments -->
    <div class="display-box">
        {#if $comments}
            {#if $comments[0]?.error}
                <p class="error">Error: {$comments[0].error}</p>
            {:else}
                <!-- Iterate over grouped comments, display paired student and teacher comments -->
                {#each $comments as commentGroup}
                    <div class="comment-group">
                        <div class="displayComps">
                        <ClassNameComponent name={commentGroup.class_name}/>
                        <TimeShower 
                            time={formatDateTime(commentGroup.displayTime).timePart} 
                            date={formatDateTime(commentGroup.displayTime).formattedDate} 
                        />
                        <TeacherNameComponent teacher={commentGroup.teacher_name}/>
                    </div>
                        <div class="side-by-side">
                            <!-- Student comment -->
                            <div class="student-comment">
                                <h3>Student Comment:</h3>
                                {#if commentGroup.student_comment}
                                    <RatingDisplayComponent label="Learned" rating={commentGroup.student_comment.learned} />
                                    <RatingDisplayComponent label="Technical Issues" rating={commentGroup.student_comment.technical_issues} />
                                    <RatingDisplayComponent label="Effort" rating={commentGroup.student_comment.effort} />
                                    <RatingDisplayComponent label="General" rating={commentGroup.student_comment.general} />
                                    <CameOnTimeDisplay onTime={commentGroup.student_comment.on_time}/>
                                    <TextDisplayForAdminComments label={commentGroup.student_comment.note} />
                                    
                                {:else}
                                    <p>No comment from student</p>
                                {/if}
                            </div>

                            <!-- Teacher comment -->
                            <div class="teacher-comment">
                                <div class="teacherComment">
                                <h3>Teacher Comment:</h3>
                                </div>
                                {#if commentGroup.teacher_comment}
                                    <RatingDisplayComponent label="Learned" rating={commentGroup.teacher_comment.learned} />
                                    <RatingDisplayComponent label="Technical Issues" rating={commentGroup.teacher_comment.technical_issues} />
                                    <RatingDisplayComponent label="Effort" rating={commentGroup.teacher_comment.effort} />
                                    <RatingDisplayComponent label="General" rating={commentGroup.teacher_comment.general} />
                                    <CameOnTimeDisplay onTime={commentGroup.teacher_comment.on_time}/>
                                    <TextDisplayForAdminComments label={commentGroup.teacher_comment.note} />
                                    
                                {:else}
                                    <p>No comment from teacher</p>
                                {/if}
                            </div>
                        </div>
                    </div>
                {/each}
            {/if}
        {:else}
            <p>No data yet. Click the button to load comments.</p>
        {/if}
    </div>
</div>

<style>
    h2{
        color:black;
    }
    .displayComps{
        display: flex;
    
        flex-direction: column;
        align-items: center;
        text-align:center;
    }
    .teacher-comment{
        text-align:center;
    }
    .student-comment{
        text-align:center;
    }
    .comment-group {
        margin-bottom: 2rem;
        padding: 1rem;
        border: 1px solid #ccc;
        background-color: #f9f9f9;
        border-radius: 5px;

    }

    .side-by-side {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .student-comment, .teacher-comment {
        width: 48%;
        
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #fff;
    }

    .student-comment {
        background-color: #f0f8ff; /* Light blue for distinction */
    }

    .teacher-comment {
        background-color: #f5f5dc; /* Light beige for distinction */
    }
</style>
