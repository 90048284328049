<script>
    import { onMount } from 'svelte';
    import TimeSlotComponent from './TimeSlotComponent.svelte'
    import TimeSlotInputComponent from './TimeSlotInputComponent.svelte';
    import FilterButtonsForSettingsPage from './FilterButtonsForSettingsPage.svelte';

    
    window.csrfToken = "{{ csrf_token }}";
    const getCsrfToken = () => {
        const csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken='));
        return csrfToken ? csrfToken.split('=')[1] : '';
    };

    const deleteTimeSlot = async(timeslot) => {
        const token = localStorage.getItem('jwtToken');
        console.log("THIS IS TOKEN")
        console.log(token)
        console.log("these are the data points")
        console.log(timeslot[0],timeslot[1],timeslot[2],timeslot[3])
        console.log("This is the entire timeslot")
        console.log(timeslot)
        const load = {
            day_of_week: timeslot[2],
            start_time: timeslot[0],
            end_time: timeslot[1],
            user: timeslot[3]
        };

        try {
            
        const response = await fetch('https://www.tutory.app/studentplayground/delete-timeslot/', {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(load)
        });

        if (response.ok) {
            console.log('Timeslot deleted successfully');
            fetchUserTimeslots()

        } else {
            console.error('Failed to delete timeslot');
        }
        } catch (error) {
        console.error('Error deleting timeslot:', error);
        }
    };



    let userTimeSlots = [];
    const handleRemove = () => {
        console.log("remove Function")
    }

    const fetchUserTimeslots = async () => {
        const token = localStorage.getItem('jwtToken')
        console.log("This is the token:)")
        console.log(token)
    
        try {
        const response = await fetch('https://www.tutory.app/studentplayground/settings/', {
            method: 'GET',
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            }
        });

        if (response.ok) {
            const data = await response.json();
            userTimeSlots = data.user_timeslots
            console.log("THis is what returns for userTimeslots:")
            console.log(userTimeSlots)// Assuming the role is returned in the JSON as { "role": "Teacher" }
        } else {
            if (response.status === 401) {
                // If response is 401 Unauthorized, redirect to login
                alert('Reikia prisijungti iš naujo.');
                localStorage.removeItem('jwtToken'); // Optional: Clear any stored tokens
                window.location.href = '#/about'; // Redirect to login page
            } else {
                // Handle other errors
                alert('Error loading settings page');
            }
            } 
        } catch (error) {
        console.error("Failed to fetch user role:", error);
        return null;
        }
    };
    onMount(async () => {
        
        await fetchUserTimeslots();
    })

</script>

<div class="top-bar">
    <a href='#/login-greeting' class="exit-button">
      <img src='/static/icons/backButtonIcon.png' alt="Exit" class="icon" />
    </a>
    <div class="label">Mano Profilis</div>
    <div class="filler"></div>
  </div>
<FilterButtonsForSettingsPage
 currentFilter='Laisvi laikai' >
 </FilterButtonsForSettingsPage>
<div class="classcontainer">
    <TimeSlotInputComponent></TimeSlotInputComponent>
    <h2>Užregistruoti laisvi laikai</h2>
    {#each userTimeSlots as timeslot}
        <TimeSlotComponent timeslot={timeslot} onRemove={deleteTimeSlot} />
  {/each}
</div>

<style>
    
    .icon {
      width: 50px;
      height: 50px;
    }
    .filler {
      width: 50px;
    }
  
    .label {
      font-size: x-large;
      color:#14695b;
      text-align:center;
    }
    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: transparent;
      border-bottom: 1px solid #000000;
      margin-bottom: 40px;
    }
    .classcontainer {
      padding: 15px;
      background-color: #eeebdb;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      gap: 10px;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
      border-radius: 8px;
      color: black;
      align-items: flex-start;
    }
    
</style>