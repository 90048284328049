<script>
    // Accept a tuple (array) with [time, date]
    export let time;

    export let date;


  </script>
  
  <style>
    .date-time-container {
      display: flex;
      align-items: center;
    }
  
    .time {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 2.5rem;
        font-weight: bold;
    }
  
    .divider {
      font-size: 1.5rem;
      font-weight: normal;
      margin-right: 0.5rem;
    }
  
    .date {
      font-size: 1rem;
      color: var(--date-color);
      font-weight: 700;
    }
  </style>
  
  <div class="date-time-container">
    <span class="time">{time}</span>
    <span class="divider">|</span>
    <span class="date">{@html date}</span>
  </div>