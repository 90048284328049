<script>
    export let subject = '';
    export let isSelected = false;
    export let onToggle;

    function handleToggle(event) {
        if (onToggle) {
            onToggle(event.target.checked); // Pass the checkbox's checked state to the parent
        }
    }

</script>

<!-- Apply the 'selected' class dynamically based on isSelected -->
<div class="checkbox-group {isSelected ? 'selected' : ''}">
    <input 
        type="checkbox" 
        id={subject} 
        checked={isSelected} 
        on:change={handleToggle} 
    />
    <div class='leftSide'>
        <label for={subject}>{subject}</label>
    </div>
</div>

<style>
    .checkbox-group {
      
        padding-right: 10px;
        padding-bottom: 12px;
        padding-top: 12px;
        left: -10px;
        position: relative;
        display: flex;
       
        gap: 20px;
        background: #FFFFFF;
        
        border-radius: 8px;
        transition: background-color 0.3s ease, color 0.3s ease;
        align-items: flex-start;
        flex-direction: row-reverse;
        margin-top:10px;
    }

    /* Apply dark green background when selected class is active */
    .checkbox-group.selected {
        margin-top:10px;
    }

    .leftSide {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 10px;
        left: -10px;
    }

    .leftSide label {
        font-size: 1rem;
        color: #333; /* Default label color */
        transition: color 0.3s ease;
        margin-left:10px;
    }


    input[type="checkbox"] {
        width: 24px;
        height: 24px;
        border-radius: 6px;
        border: 2px solid #333;
        background-color: white;
        appearance: none;
        outline: none;
        cursor: pointer;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        transition: background-color 0.3s ease;
    }

    /* Change checkbox color and style when checked */
    input[type="checkbox"]:checked {
        background-color: #14695B; /* Dark green */
        border: none;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"%3E%3Cpolyline points="20 6 9 17 4 12"/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: center;
    }
</style>
