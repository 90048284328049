<script>
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';

  let formData = {
    username: '',
    password: ''
  };

  let errorMessage = ''; // Add a variable to store error messages
  
  export function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}
  async function handleSubmit(event) {
    event.preventDefault();
    errorMessage = ''; // Reset error message before new submission

    console.log('Form data:', formData); // Log the form data being sent

    try {
        console.log('Sending request to token endpoint...');
        
        const response = await fetch('https://www.tutory.app/studentplayground/api/token/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        });

        console.log('Received response:', response); // Log the entire response object

        if (response.ok) {
            console.log('Response OK:', response.status);
            
            const data = await response.json();
            console.log('Response data:', data); // Log the response data
            
            // The JWT token is typically in data.access
            if (data.access) {
                console.log('JWT Token:', data.access);
                const token = data.access;
                const decodedToken = parseJwt(token);

                // Save the token to localStorage
                localStorage.setItem('jwtToken', data.access);
                localStorage.setItem("jwtExpiry", decodedToken.exp * 1000);
                
                // Check if this is the first login or credentials need to be updated
                if (formData.password === 'tutory123') {
                    // Redirect to a profile update page
                    push('/update-credentials');
                } else {
                    // If it's not the first login, redirect to the regular greeting page
                    push('/login-greeting');
                }
            }
        } else {
            console.warn('Response NOT OK:', response.status);
            
            const errorData = await response.json();
            console.log('Error data:', errorData); // Log the error data from the response
            
            errorMessage = errorData.message || "Neteisingi prisijungimo duomenys" || 'Incorrect username or password'; // Set error message
        }
    } catch (error) {
        console.error('Fetch error:', error); // Log any fetch errors that occur
        errorMessage = error.toString(); // Store the error message as a string
    }

    console.log('Error message:', errorMessage); // Log the final error message
}

  function getCsrfToken() {
    return document.cookie.split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
  }

  
</script>

<style>
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #fffffa;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px; /* Adjust as needed */
    margin: 40px auto
  }

  div {
    padding: 30px;
  }

  form {
    width: 90%;
    padding: 20px;
    background-color: #eeebdb;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
    align-self: baseline;
    color:#14695b;
  }

  input, button {
    width: -webkit-fill-available;
    display: block;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }

  button {
    background-color: #fab45a;
    color: #674a25;
    font-weight: bold;
    border: 1px solid;
    border-color: #674a25;
    height:50px;
  }

  button:hover {
    background-color: #674a25;
    color:#fab45a;
    border-color:#fab45a;
  }

  
  .error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
  }

  @media (max-aspect-ratio: 4/3) {
    .content {
      width: calc(100%-40px);
    }
  }

  @media (min-aspect-ratio: 4/3) {
    .content {
      width: 80%;
      margin: 40px auto 0;
    }
  }
</style>

<div class="background"></div>

<div class="content">
  <form on:submit={handleSubmit}>
    <img src="/static/images/Tutory Logo Black 1.png" alt="TUTORY Logo" class="logo" style="max-width: 200px;">
    <label for="username">Vartotojo vardas</label>
    <input type="text" bind:value={formData.username} placeholder="Username">
    <label for="password">Slaptažodis</label>
    <input type="password" bind:value={formData.password} placeholder="Password">
    <button type="submit">Prisijungti</button>
    {#if errorMessage}
      <div class="error-message">{errorMessage}</div>
    {/if}
  </form>
</div>
