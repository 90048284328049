<script>
    export let label;
</script>
<div class="noteDiv">
    {label}
</div>
<style>
    .noteDiv {
        background-color: transparent;
        width: 100%;
        border-radius: 6px;
        display: flex;
        justify-content: space-around;
        padding: 10px; /* Add padding to move the content */
        box-sizing: border-box;
        border: 2px solid #baccff;
    }
</style>