<script>
  import ClassName from './ClassNameComponent.svelte';
  import TeacherName from './TeacherNameComponent.svelte';
  import TimeShower from './TimeShowerComponent.svelte';
  import { onMount } from 'svelte';
  export let params;
  import { push } from 'svelte-spa-router';
  import TextField from './TextFieldComponent.svelte';
  import StarRating from './StarsInput.svelte'
  import CommentTipComponent from './CommentTipComponent.svelte';
  import CheckBoxForLeaveComment from './CheckBoxForLeaveComment.svelte';
  
  let classinfo = {};
  let isUserTeacher = null;
  let classId = params.id;

  let technicalIssuesRating = 1;  // Track the selected rating for technical issues (1-5)
  let learned = 1;
  let effortRating = 1;
  let generalRating = 1;
  let extraDetails = '';
  let isCheckboxSelected = false; // State managed in the parent
  let isHomeworkSelected = false;
  let isCompleteSelected = false;

  function handleRatingUpdate(field, value) {
    if (field === 'technicalIssuesRating') {
      technicalIssuesRating = value;
    } else if (field === 'effortRating') {
      effortRating = value;
    } else if (field === 'learned') {
      learned = value;
    } else if (field === 'generalRating') {
      generalRating = value;
    }
  }



function handleCheckboxToggle(checked) {
  isCheckboxSelected = checked; // Update the state with the boolean value
  console.log("This is isSelected:", isCheckboxSelected); // Log the checked value
}
function handleHomeworkToggle(checked){
  isHomeworkSelected = checked;
  console.log("This is isSelected:", isCheckboxSelected);
}
function handleCompleteToggle(checked) {
  isCompleteSelected = checked;
  console.log("This is isCompleteSelected:", isCompleteSelected);
}
  // Handle form submission
async function handleSubmit() {
  const payload = {
    technical_issues: technicalIssuesRating,  // Send the star rating
    learned: learned,
    effort: effortRating,
    general: generalRating,
    extra: extraDetails,
    on_time: isCheckboxSelected,
    homework: isHomeworkSelected,
    complete: isCompleteSelected, 

  };
  try {
    console.log("we have made it to 2")
    const response = await fetch(`https://www.tutory.app/studentplayground/classNote/${classId}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
      },
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error submitting form:", errorData.error);
    } else {
      push('/note-success');
    }
  } catch (error) {
    console.error("Error submitting form:", error);
  }
}

  // Helper function to format date and time
  const formatDateTime = (datetime) => {
      if (!datetime) return { timePart: '', formattedDate: '' }; // Handle missing datetime

      let parts = datetime.split(" ");
      let datePart = parts[0];
      let timePart = parts[1];
      let dayPart = parts[2];

      let formattedDate = `${dayPart}<br>${datePart}`;
      return { timePart, formattedDate };
  };
  const fetchClassData = async () => {
    try {
      const response = await fetch(`https://www.tutory.app/studentplayground/api/get_class_data/${classId}/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
  
      const data = await response.json();
      console.log("Fetched classinfo:", data.classinfo);  // Log to check the structure
      classinfo = data.classinfo;
      // console.log("This is the technical issues value:");
      // console.log(data.classinfo.existing_note.technical_issues);
      // console.log(data.classinfo.existing_note.learned);
      // console.log(data.classinfo.existing_note.effort);
      // console.log(data.classinfo.existing_note.general);
      // console.log(data.classinfo.existing_note.note);
      // console.log(data.classinfo.existing_note.on_time);
      technicalIssuesRating = data.classinfo.existing_note.technical_issues;
      learned = data.classinfo.existing_note.learned;
      effortRating = data.classinfo.existing_note.effort;
      generalRating = data.classinfo.existing_note.general;
      extraDetails = data.classinfo.existing_note.note;
      isCheckboxSelected = data.classinfo.existing_note.on_time;
      isHomeworkSelected = data.classinfo.existing_note.homework;
      if (response.ok) {
        // Redirect to success page if request was successful
        console.log("all of the data about the comment has been")
      } else {
          if (response.status === 401) {
              // If response is 401 Unauthorized, redirect to login
              alert('Reikia prisijungti iš naujo.');
              localStorage.removeItem('jwtToken'); // Optional: Clear any stored tokens
              window.location.href = '#/about'; // Redirect to login page
          } else {
              // Handle other errors
              alert('Error loading form');
          }
      }
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };
  async function checkIfUserIsTeacher() {
  console.log("ive at least made it this far")
  try {
      // Retrieve JWT token from localStorage/sessionStorage/cookie (depending on how you store it)
      const token = localStorage.getItem('jwtToken'); 
      console.log("THIS IS THE TOKEN")
      console.log(token)
      if (!token) {
          throw new Error('JWT token not found. Please log in.');
      }

      const response = await fetch('https://www.tutory.app/studentplayground/isUserTeacher/', {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });

      if (!response.ok) {
          // Handle error response from the server
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to check teacher status');
      }

      const data = await response.json();
      isUserTeacher = data.isUserTeacher;
      console.log("this is the value of teacher :)")
      console.log(isUserTeacher)
  } catch (error) {
      errorMessage = error.message;
  }
}

  // Fetch class data on component mount
  onMount(async () => {
    fetchClassData()
    console.log("THIS IS THE VERY FIRST STEP")
    checkIfUserIsTeacher();
  });
</script>

<div class="top-bar">
<a href='#/schedule' class="exit-button">
  <img src='/static/icons/backButtonIcon.png' alt="Exit" class="icon" />
</a>
<div class="label">Palikti Atsiliepimą</div>
<div class="filler"></div>
</div>
<div class='form-container'>
<!-- Render the class details -->
<ClassName name={classinfo.name} />
{#if !isUserTeacher}

  <TeacherName teacher={classinfo.teacher} />
{/if}
<TimeShower 
  time={formatDateTime(classinfo.time).timePart} 
  date={formatDateTime(classinfo.time).formattedDate} />

  
  <label for="technicalIssuesRating">
    Techninis pamokos sklandumas
    <CommentTipComponent infoText="<strong>Techninis pamokos sklandumas</strong><br>
                                  <strong>1 Žvaigždutė =</strong> Pamoka neįvyko dėl techninių nesklandumų.<br>
                                  <strong>3 Žvaigždutės =</strong> Buvo techninių nesklandumų, bet pamoka įvyko.<br>
                                  <strong>5 Žvaigždutės =</strong> Techninių nesklandumų nebuvo.<br>
                                  Jeigu buvo techninių nesklandumų, aprašykite juos komentarų skiltyje." />
  </label>

<StarRating 
  rating={technicalIssuesRating}         
  onRate={(value) => handleRatingUpdate('technicalIssuesRating', value)}
/>
<label for="learned">
  {@html !isUserTeacher 
  ? 'Mokytojo pastangos ir susikaupimas pamokoje' 
  : 'Mokinio pastangos ir susikaupimas pamokoje'}
  <CommentTipComponent infoText="<strong>Pastangos ir dalyvavimas</strong><br>
                                  <strong>1 žvaigždutė=</strong> Mokinys buvo išsiblaškęs ir nedalyvavo pamokoje.<br>
                                  <strong>3 žvaigždutės=</strong> Mokinys nedalyvavo pilnu pajėgumu, bet nebuvo išsiblaškęs.<br>
                                  <strong>5 žvaigždutės=</strong> Mokinys aktyviai dalyvavo ir rodė maksimalias pastangas." />
</label>
<StarRating 
  rating={learned}         
  onRate={(value) => handleRatingUpdate('learned', value)}
/>
<label for="effortRating">
  {@html !isUserTeacher 
  ? 'Mokytoja/as įsigilinę mokomą temą' 
  : 'Mokinė/ys suprato ir išmoko mokomą temą'}
  <CommentTipComponent infoText="<strong>Įsigilinimas į mokoma temą</strong><br>
  <strong>1 žvaigždutė=</strong> Mokinė/ys sunkiai suprato temą ir beveik nieko neišmoko.<br>
  <strong>3 žvaigždutės=</strong> Mokinė/ys suprato dalį temos, tačiau reikės paskirti daugiau laiko.<br>
  <strong>5 žvaigždutės=</strong> Mokinė/ys puikiai suprato ir įsisavino mokomą temą." />
</label>
<StarRating 
  rating={effortRating}         
  onRate={(value) => handleRatingUpdate('effortRating', value)}
/>
<label for="generalRating">
  {@html !isUserTeacher 
  ? 'Bendras Pamokos įvertinimas' 
  : 'Bendras Pamokos įvertinimas'}
</label>
<StarRating 
  rating={generalRating}         
  onRate={(value) => handleRatingUpdate('generalRating', value)}
/>

<CheckBoxForLeaveComment
  subject="Mokinė/ys atėjo laiku į pamoką"
  isSelected={isCheckboxSelected}
  onToggle={handleCheckboxToggle}
/>

<CheckBoxForLeaveComment
  subject="Mokinė/ys atliko namų darbus"
  isSelected={isHomeworkSelected}
  onToggle={handleHomeworkToggle}
/>

<CheckBoxForLeaveComment
  subject="Pamoka įvyko"
  isSelected={isCompleteSelected}
  onToggle={handleCompleteToggle}
/>

<label for="extraDetails" class="custom-label">
    <CommentTipComponent 
      infoText="<strong>Papildomi komentarai</strong><br>
      Prašome pateikti išsamią informaciją ar pastabas apie pamoką, ypač jei kuris nors iš aukščiau pateiktų įvertinimų mažesnis nei 5"
      className="last-tooltip"/>
    Papildomi komentarai <br>
    
</label>
<TextField 
      id="extraDetails"
      placeholder="" 
      bind:value={extraDetails} 
  />


<!-- Submit button -->
<button type="button" on:click={handleSubmit}>Pateikti Atsiliepimą</button>
</div>

<style>
.custom-label {
    display: flex;
    flex-direction: row;  /* Horizontal alignment of text and icon */
    align-items: center;  /* Keep icon aligned with text vertically */
    margin-bottom: 5px;
    margin-top: 20px;
    font-size: 1.17em;
    font-weight: bold;
    gap: 5px; /* Space between text and the CommentTipComponent icon */
    flex-direction: row-reverse;
}
  
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: transparent;
    border-bottom: 1px solid #000000;
    margin-bottom: 40px;
  }
  .filler {
    width: 50px;
  }
  label{
    display:flex;
    flex-direction: row;
    align-items: center;
  }
  .label {
    font-size: x-large;
  }
  
  .icon {
    width: 50px;
    height: 50px;
  }
  .form-container{
      padding: 15px;
      background-color: white;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      gap: 10px;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
      border-radius: 8px;
      flex: none;
      color: black;
      align-items: flex-start;
  }
  button{
      height: 60px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 20px;
      gap: 10px;
      border-radius: 6px;
      border: 2px solid #333;
      font-weight: bold;
      font-size: large;
      background: white;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
      border-radius: 8px;
      transition: background-color 0.3s ease, color 0.3s ease; /* Add color transition */
      }
      button:hover{
        background-color: #14695b;
        color:white;
      }
</style>
