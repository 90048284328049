<script>
    export let student;
  </script>
  
  <style>
    .teacher-name {
      font-size: 1rem;
      color: #000000;
      margin-bottom: 1rem;
    }
  </style>
  
  <div class="teacher-name">
    Mokiniai: {student}
  </div>