<script>
    export let timeslot;
    export let onRemove;
  
    const daysInLithuanian = {
      Monday: 'Pirmadienis',
      Tuesday: 'Antradienis',
      Wednesday: 'Trečiadienis',
      Thursday: 'Ketvirtadienis',
      Friday: 'Penktadienis',
      Saturday: 'Šeštadienis',
      Sunday: 'Sekmadienis'
    };
  
    let startTime = timeslot[0];
    let endTime = timeslot[1];
    let dayInEnglish = timeslot[2];
    let dayInLithuanian = daysInLithuanian[dayInEnglish];


  </script>
  
  <style>
    .timeslot {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
    }
  
    .timeslot button {
      border: 1px solid #c0c0c0;
      background-color: #d3e3d3;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .timeslot button:hover {
      background-color: #a8c1a8;
    }
  
    .minus-icon {
      width: 16px;
      height: 16px;
      fill: #555;
    }
  </style>
  
  <div class="timeslot">
    <span>{startTime} – {endTime} {dayInLithuanian}</span>
    <button on:click={() => onRemove(timeslot)}>
      <svg class="minus-icon" viewBox="0 0 24 24">
        <rect x="5" y="11" width="14" height="2"/>
      </svg>
    </button>
  </div>
  