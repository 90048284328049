<script>
    import { onMount } from 'svelte';
    import { push } from 'svelte-spa-router';

    let newUsername = '';
    let newPassword = '';
    let newRepPassword = '';
    let errorMessage = '';

    async function handleUpdateCredentials(event) {
        event.preventDefault();
        errorMessage = ''; // Reset error message before new submission

        try {
            if (newPassword === newRepPassword){
                const token = localStorage.getItem('jwtToken');
                
                // Update user details endpoint
                const response = await fetch('https://www.tutory.app/studentplayground/api/update_credentials/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        username: newUsername,
                        password: newPassword
                    })
                });

                if (response.ok) {
                    // Redirect to login greeting after successful update
                    push('/login-greeting');
                } else {
                    const errorData = await response.json();
                    errorMessage = errorData.message || 'Nepavyko atnaujinti duomenų';
                }
                }else{
                    errorMessage ="Nesutampa slaptažodžiai";
                }
        } catch (error) {
            console.error('Error updating credentials:', error);
            errorMessage = error.toString();
        }
    }
</script>

<main>
    <h1>Atnaujinkite savo duomenis</h1>
    <div class='classcontainer'>
    <form on:submit={handleUpdateCredentials}>
        <label>
            Naujas prisijungimo vardas<br>
            <input type="text" bind:value={newUsername} required>
        </label>
        <label>
            Naujas slaptažodis<br>
            <input type="password" bind:value={newPassword} required>
        </label>
        <label>
            Pakartoti slaptažodį<br>
            <input type="password" bind:value={newRepPassword} required>
        </label>
        <button type="submit"><span>Atnaujinti</span></button>
    </form>
 
    {#if errorMessage}
        <p class="error">{errorMessage}</p>
    {/if}
</div> 
</main>

<style>
    label{
        padding: 3px;
        font-weight: lighter;
        margin-bottom: 20px;
        font-size: larger;
        display: block;
    }
    h1{
        text-align: center;
    }
     button span {
        font-weight: bolder;
        font-size:larger;
    }

    button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
        gap: 10px;
    
        width: 100%;
        height: 60px;
    
        /* Purple background */
        background-color: white;
    
        /* Black border */
        border: 1px solid #000000;
    
        /* Box shadow */
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    
        /* Rounded corners */
        border-radius: 8px;
    
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    
        /* Remove default button styling */
        outline: none;
        cursor: pointer;
  
        /* Remove the default button background on focus */
        background-image: none;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    /* Button hover effect */
    button:hover {
        background-color: #14695b;
        color:white;
    }
    

    /* Text inside the button */
    span {
        font-size: 1rem;
        font-weight: bold;
        color: #000000;
    }
    .error {
        color: red;
    }
    main{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .classcontainer {
      padding: 15px;
      background-color: white;
      display: flex;
      max-width:400px;
      flex-direction: column;
      margin-bottom: 15px;
      gap: 10px;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
      border-radius: 8px;
      color: black;
      align-items: flex-start;
    }
</style>
