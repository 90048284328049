<script>
    import Checkbox from './Checkbox.svelte';
    export let selectedSubjects = [];

    let subjects = [
        { name: 'Matematika', selected: false, icon: '/static/icons/mathIcon.png' },
        { name: 'Anglų k.', selected: false, icon: '/static/icons/englishIcon.png' },
        { name: 'Istorija', selected: false, icon: '/static/icons/historyIcon.png' },
        { name: 'Lietuvių k.', selected: false, icon: '/static/icons/lithuanianIcon.png' },
        { name: 'Chemija', selected: false, icon: '/static/icons/chemistryIcon.png' },
        { name: 'Fizika', selected: false, icon: '/static/icons/physicsIcon.png' },
        { name: 'Biologija', selected: false, icon: '/static/icons/biologyIcon.png' },
        { name: 'IT', selected: false, icon: '/static/icons/ITICon.png' }
    ];

    const toggleSubject = (index) => {
        subjects[index].selected = !subjects[index].selected;
        selectedSubjects = subjects.filter(subject => subject.selected).map(subject => subject.name);
    };
</script>

<div class="checkbox-area">
    {#each subjects as subject, i}
        <Checkbox 
            icon={subject.icon}
            subject={subject.name} 
            isSelected={subject.selected} 
            onToggle={() => toggleSubject(i)} 
        />
    {/each}
</div>

<style>
    .checkbox-area {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width:100%;
    }
</style>
