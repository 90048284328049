<script>
    export let label;  // Label for the rating (e.g., "Learned", "Effort", etc.)
    export let rating; // The actual rating value (number between 1 and 5)

    // Function to determine the class for the rating number based on its value
    function getRatingClass(rating) {
        if (rating === 1) return 'rating-red';
        if (rating === 2) return 'rating-orange';
        if (rating === 3) return 'rating-yellow';
        if (rating === 4) return 'rating-yellow-green';
        if (rating === 5) return 'rating-green';
        return ''; // Default case
    }
</script>

<style>
    .rating-box {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        flex-direction: column;
    }


    .rating-box span {
        flex: 1;
        font-weight: bold;
    }

    .rating-box .rating-value {
        flex: 2;
        font-size: 1.5rem;
        font-weight: bold;
    }

    /* Rating colors */
    .rating-red {
        color: red;
    }

    .rating-orange {
        color: orange;
    }

    .rating-yellow {
        color: #e8e531
    }

    .rating-yellow-green {
        color: #9acd32; /* Between yellow and green */
    }

    .rating-green {
        color: green;
    }
</style>

<div class="rating-box">
    <span>{label}</span>
    <div class="rating-value {getRatingClass(rating)}">{rating}</div>
</div>
