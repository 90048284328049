<script>
    import { onMount } from 'svelte';
    import { push } from 'svelte-spa-router';
    import FilterButtonsForSettingsPage from "./FilterButtonsForSettingsPage.svelte";

    let email = 'john@email.com';  // Initial email value
    let receiveMessages = true;    // Checkbox for SMS notifications
    
    async function updateAccount() {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            console.error('No JWT token found. User might not be logged in.');
            return;
        }
        try {
            const response = await fetch('https://www.tutory.app/studentplayground/settings/account/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    receiveMessages: receiveMessages
                })
            });

            if (response.ok) {
                const result = await response.json();
                console.log(result.message);  // Success message
                window.location.reload();
            } else {
                const error = await response.json();
                console.log('Error:', error);
            }
        } catch (error) {
            console.log('Fetch error:', error);
        }
    }
    async function fetchInitialData() {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            console.error('No JWT token found. User might not be logged in.');
            return;
        }
        try {
            const response = await fetch('https://www.tutory.app/studentplayground/settings/account/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
    
            });

            if (response.ok) {
                const result = await response.json();
                email= result.email
                receiveMessages = result.receiveMessages
                console.log("these are the data")
                console.log(result.email,result.receiveMessages);  // Success message
            } else {
                const error = await response.json();
                console.log('Error:', error);
            }
        } catch (error) {
            console.log('Fetch error:', error);
        }
    }
    


    function logout() {
        // Clear JWT token from localStorage or sessionStorage
        localStorage.removeItem('jwtToken'); // assuming your JWT is stored in localStorage
        
        // Optionally clear other user-related data from storage
        // localStorage.removeItem('userInfo');

        console.log('User logged out');

        // Redirect the user to the login page
        push('/about'); // Use the appropriate path to your login page
    }




    onMount(async () => {
        await fetchInitialData();
       
        })

</script>

<style>
    .container {
        display: flex;
        flex-direction: column;
        margin-bottom:15px;
        gap: 10px;
        flex: none;
        color:black;
        align-items: flex-start;
    }
    .input-group {
        margin-top: 20px;
        width:100%;
    }
    label {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
        display: block;
    }
    input[type="email"] {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color:#eeebdb;
    }
    button {
    
        margin-top: 20px;
        height: 60px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
        gap: 10px;
        border-radius: 6px;
        border: 1px solid #333;
        font-weight: bold;
        font-size: large;
        background-color:#fab45a;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        transition: background-color 0.3s ease, color 0.3s ease; /* Add color transition */

    }
    button.logout {
        background-color: transparent;

        color: black;
    }
    button.logout:hover {
        background-color: #14695b;
        color:white;
    }
    button:hover {
        background-color: #14695b;
        color:white;
    }
    .checkbox-group {
        display: flex;
        flex-direction: column;
    }
    .smaller-part{
        display:flex;
        flex-direction: row;
        gap:10px;
    }
    
    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: transparent;
      border-bottom: 1px solid #000000;
      margin-bottom: 40px;
    }
    .filler {
      width: 50px;
    }
  
    .label {
      font-size: x-large;
    }
    
    .icon {
      width: 50px;
      height: 50px;
    }
    input[type="checkbox"] {
        width: 24px;
        height: 24px;
        border-radius: 6px;
        border: 2px solid #333;
        background-color: white;
        appearance: none;
        outline: none;
        cursor: pointer;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        transition: background-color 0.3s ease;
    }

    input[type="checkbox"]:checked {
        background-color: #14695B;
        border: none;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"%3E%3Cpolyline points="20 6 9 17 4 12"/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: center;

    }
</style>

<div class="top-bar">
    <a href="#/login-greeting" class="exit-button">
      <img src="/static/icons/backButtonIcon.png" alt="Exit" class="icon" />
    </a>
    <div class="label">Mano Profilis</div>
    <div class="filler"></div>
  </div>
<FilterButtonsForSettingsPage currentFilter='Paskyra'></FilterButtonsForSettingsPage>
<div class="container">
    <div class="input-group">
        <label for="email">Keisti el.pašto adresą</label>
        <input id="email" type="email" bind:value={email} placeholder="john@email.com">
    </div>

    

    <div class="checkbox-group">
        <label for="sms-notification">Priminimai apie rezervuotą pamoką</label>
        <div class="smaller-part">
            <input id="sms-notification" type="checkbox" bind:checked={receiveMessages}>
            <span for="sms-notification">Gauti SMS žinutę</span>
        </div>
    </div>
    <button on:click={updateAccount}>Išsaugoti</button>

    
</div>
<button class="logout" on:click={logout}>Atsijungti</button>
