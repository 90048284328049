<script>
    export let name;
  </script>
  
  <style>
    .class-name {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  </style>
  
  <div class="class-name">
    {name}
  </div>