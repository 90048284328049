<script>
    import { createEventDispatcher } from 'svelte';
  
    // Initialize the dispatcher for sending events to the parent
    const dispatch = createEventDispatcher();
  
    // Set initial value to current date and time, formatted for 'datetime-local' input
    let currentDateTime = new Date().toISOString().slice(0, 16); // YYYY-MM-DDTHH:MM
    let datetime = currentDateTime; // Default to current date and time
    
    function isPlaceholder() {
        return !datetime;  // Returns true if the datetime is empty
    }

    // Function to handle submit
    function handleSubmit() {
      // Emit the selected datetime to the parent
      dispatch('submit', { datetime });
    }
  </script>
  
  <!-- Component markup -->
  <div>
    <!-- Input for selecting date and time -->
    <input
        type="datetime-local"
        bind:value={datetime}
        class:is-placeholder={isPlaceholder()} />

    <!-- Button to submit the selected datetime -->
    <button on:click={handleSubmit}>
      Patvirtinti
    </button>
  </div>
  
  <style>
    /* Optional styles */
    input.is-placeholder {
        color: transparent;
    }

    input.is-placeholder::-webkit-datetime-edit {
        color: transparent;
    }

    div {
        display: flex;
        gap: 10px;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
    }
  
    input {
        
        width:100%;
        padding: 0.5rem;
        font-size: 1rem;
    }
  
    button {
        width: 100%;
        font-weight:700;
        height: 60px;
        background-color: #fab45a;
        border: 1px solid #000000;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
    
    }
  
    button:hover {
      background-color: #14695b;
      color:white;
    }
  </style>
  