<script>
    export let onTime;
  </script>
  
  <style>
    .status {
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        margin: 10px 0;
        justify-content: center;
        height: 80px;
        }
  
    .text {
      margin-left: 8px;
    }
  
    .green-check {
      color: #58ff04
    }
  
    .red-cross {
      color: red;
    }
  </style>
  
  <div class="status">
    {#if onTime}
      <span class="green-check">✅</span>
      <span class="text">Atejo laiku i pamoka</span>
    {:else}
      <span class="red-cross">❌</span>
      <span class="text">Velavo i pamoka</span>
    {/if}
  </div>
  