<script>
    import { push } from 'svelte-spa-router';
    import CheckboxArea from './CheckboxAreaComponent.svelte';
    import TextField from './TextFieldComponent.svelte';
    import Dropdown from './DropDownComponent.svelte';
    import DateTimePicker from './DateTimePickerComponent.svelte';



    let selectedSubjects = [];
    let topicsOfInterest = '';
    let selectedClass = '';
    let datetimeValues = [''];

    const classChoices = [
        ['6', '6'],
        ['7', '7'],
        ['8', '8'],
        ['9', '9'],
        ['10', '10'],
        ['11', '11'],
        ['12', '12'],
        ['13', 'Universitetas'],
    ];

    const addDateTime = () => {
        datetimeValues = [...datetimeValues, ''];
    };

    const removeDateTime = (index) => {
        datetimeValues = datetimeValues.filter((_, i) => i !== index);
    };

    const handleSubmit = async () => {
        const data = {
            subjects: selectedSubjects,
            topics: topicsOfInterest,
            selectedClass: selectedClass,
            datetimeValues: datetimeValues,
        };
        const token = localStorage.getItem('jwtToken'); 
        try {
            const response = await fetch('https://www.tutory.app/studentplayground/registerForClass/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                    'Authorization': `Bearer ${token}`  // Include JWT token in Authorization header
                    },
                    credentials: 'include',
                    body: JSON.stringify(data)  // Optionally include credentials if using cookies
                    });
                

                    if (response.ok) {
                        // Redirect to success page if request was successful
                        push('/register-success');
                    } else {
                        if (response.status === 401) {
                            // If response is 401 Unauthorized, redirect to login
                            alert('Reikia prisijungti iš naujo.');
                            localStorage.removeItem('jwtToken'); // Optional: Clear any stored tokens
                            window.location.href = '#/about'; // Redirect to login page
                        } else {
                            // Handle other errors
                            alert('Error submitting form!');
                        }
                    }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
</script>
<div class="top-bar">
    <a href='#/login-greeting' class="exit-button" >
      <img src='/static/icons/backButtonIcon.png' alt="Exit" class="icon" />
    </a>
    <div class="label">Registruotis pamokai</div>
    <div class="filler"></div>
  </div>
<div class="student-form">
    <h2>Pasirinkite pamokas</h2>
    <CheckboxArea bind:selectedSubjects={selectedSubjects} />

    <TextField 
        label="Nuo kurios temos norėtumėte pradėti?" 
        placeholder="Įrašykite specifines temas, kurios jus domina" 
        bind:value={topicsOfInterest} 
    />

    <Dropdown 
        label="Pasirinkite klasę"
        options={classChoices}
        bind:selectedValue={selectedClass}
    />
    <!-- Dynamic DateTime Picker List -->
    {#each datetimeValues as datetimeValue, index}
        <DateTimePicker 
            id={`datetime-${index}`} 
            bind:value={datetimeValues[index]} 
            onRemove={() => removeDateTime(index)} 
        />
    {/each}
    
    <button type="button" class='addDatetime' on:click={addDateTime}>Pridėti laiką</button>

    <button on:click={handleSubmit} type="button" class="submitBut">Pateikti Užklausą</button>
</div>
<style>
    .submitBut{
        background-color: #fab45a;
        color:black;
        border: 1px solid #000000;

    }
    .submitBut:hover{
        background-color: #c98f43;
        
        color:black;
    }
    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: transparent;
      border-bottom: 1px solid #000000;
      margin-bottom: 40px;
    }
    .filler {
      width: 50px;
    }
  
    .label {
      font-size: x-large;
      text-align: center;
      color:#14695b;
    }
    
    .icon {
      width: 50px;
      height: 50px;
    }
    .student-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .addDatetime{
        display: flex;
        justify-content: center;
        align-items: center;
        height:40px;
        background-color: #fab45a;
        color:black;
    }
    .addDatetime:hover{
        background-color: #c98f43;
        color:black;
    }

    button{
        margin-top: 20px;
        height: 60px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
        gap: 10px;
        border-radius: 6px;
        border: 1px solid #333;
        font-weight: bold;
        font-size: large;
        background: transparent;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        transition: background-color 0.3s ease, color 0.3s ease; /* Add color transition */
    }
    button:hover {
        background-color: #14695b;
        color:white;
    }
   
</style>
