<script>    
    import TeacherFinderComponent from "./TeacherFinderComponent.svelte";
    import RefreshDatabaseButtonComponent from "./RefreshDatabaseButtonComponent.svelte";
    import FindStudentsComments from "./FindStudentsComments.svelte";
</script>
<div class="top-bar">
    <a href="#/login-greeting" class="exit-button">
      <img src="/static/icons/backButtonIcon.png" alt="Exit" class="icon" />
    </a>
    <div class="label">Admin</div>
    <div class="filler"></div>
  </div>
<div class="classcontainer">
    <TeacherFinderComponent></TeacherFinderComponent>
</div>
<FindStudentsComments></FindStudentsComments>
<RefreshDatabaseButtonComponent></RefreshDatabaseButtonComponent>
<style>
    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: transparent;
      border-bottom: 1px solid #000000;
      margin-bottom: 40px;
    }
    .filler {
      width: 50px;
    }
  
    .label {
      font-size: x-large;
    }
    
    .icon {
      width: 50px;
      height: 50px;
    }
    .classcontainer{
        padding: 15px;
        background-color: white;
        display: flex;
        flex-direction: column;
        margin-bottom:15px;
        gap: 10px;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        flex: none;
        color:black;
        align-items: flex-start;

      }
</style>