<script>
    import { onMount } from 'svelte';
    import { writable } from 'svelte/store';
    import TeacherFinderDisplayComponent from './TeacherFinderDisplayComponent.svelte';

    // Dummy subjects for the dropdown, you can fetch these from an API as well.
    const subjects = [
        "Matematika",
        "Anglų kalba",
        "Lietuvių kalba",
        "Istorija",
        "Chemija",
        "Fizika",
        "Biologija",
        "IT",
        "Ispanų kalba",
        "Matematika Rus.",
        "Lietuvių k. Rus.",
        "Fizika Rus.",
        "Matematika Angl.",
        "Lietuvių k. Angl.",
        "Fizika Angl.",
    ];

    // Grade options
    const grades = Array.from({ length: 8 }, (_, i) => i + 6); // [6, 7, 8, 9, 10, 11, 12, 13]

    // Stores for form data
    let selectedSubject = writable("");
    let selectedGrade = writable(6);
    let timeSlots = writable([]);

    // Store for API response data
    let responseData = writable(null);

    // Add a new time slot
    function addTimeSlot() {
        timeSlots.update((slots) => [...slots, { start_time: "", end_time: "", day_of_week: "1" }]);
    }

    // Remove a specific time slot
    function removeTimeSlot(index) {
        timeSlots.update((slots) => slots.filter((_, i) => i !== index));
    }

    // Update a specific time slot
    function updateTimeSlot(index, key, value) {
        timeSlots.update((slots) => {
            slots[index][key] = value;
            return slots;
        });
    }

    // Submit form data
    async function submitForm() {
        // Use `$` to access the current values of the stores
        const subject = $selectedSubject;
        const grade = $selectedGrade;
        const slots = $timeSlots;

        const payload = {
            subject: subject,
            grade: grade,
            time_slots: slots,
        };

        try {
            const token = localStorage.getItem('jwtToken');
            const response = await fetch('https://www.tutory.app/studentplayground/teacher-finder/', {
                method: 'POST',
                headers: { 
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                // Clear the store to force a re-render
                responseData.set(null);
                await new Promise(r => setTimeout(r, 0)); // Let Svelte detect the change
                responseData.set(data); // Store the response data
                console.log("This is returned data", data);
                
            } else {
                const errorData = await response.json();
                console.error('Error:', errorData.error);
                responseData.set("No teachers were found with this specs"); // Set error data
            }
        } catch (error) {
            console.error('Request failed:', error);
            responseData.set({ error: 'Request failed' }); // Set general error
        }
    }

</script>

<style>
    .first_container {
        display: flex;
        gap: 30px;
    }
    .form-group {
        margin-bottom: 1em;
    }
    .time-slot {
        display: flex;
        gap: 1em;
        margin-bottom: 0.5em;
    }
    button {
        margin-top: 1em;
    }
    .container_zero {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .labelDiv {
        display: flex;
        justify-content: space-around;
    }
  
    .error {
        color: red;
    }
</style>

<div class="container_zero"> 
    <div class='labelDiv'>
        <span>MOKYTOJU IEŠKOTOJAS</span>
    </div>
    <div class='first_container'>
        <div class="form-group">
            <label for="subject">Subject:</label>
            <select id="subject" bind:value={$selectedSubject}>
                <option value="" disabled selected>Select a subject</option>
                {#each subjects as subject}
                    <option value={subject}>{subject}</option>
                {/each}
            </select>
        </div>
    
        <div class="form-group">
            <label for="grade">Grade:</label>
            <select id="grade" bind:value={$selectedGrade}>
                {#each grades as grade}
                    <option value={grade}>{grade}</option>
                {/each}
            </select>
        </div>
    </div>

    <div class="form-group">
        {#each $timeSlots as slot, index}
            <div class="time-slot">
                <select bind:value={slot.day_of_week} on:change={(e) => updateTimeSlot(index, 'day_of_week', e.target.value)}>
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>
                    <option value="7">Sunday</option>
                </select>
                <input type="time" bind:value={slot.start_time} on:change={(e) => updateTimeSlot(index, 'start_time', e.target.value)} />
                <input type="time" bind:value={slot.end_time} on:change={(e) => updateTimeSlot(index, 'end_time', e.target.value)} />
                <button type="button" on:click={() => removeTimeSlot(index)}>Remove</button>
            </div>
        {/each}
        <button type="button" on:click={addTimeSlot}>Add Time Slot</button>
    </div>

    <button type="button" on:click={submitForm}>Submit</button>

    <!-- Display Box -->
    <div class="display-box">
        <!-- Reactive Display of Response Data -->
        {#if $responseData}
            {#if $responseData.error}
                <p class="error">Error: {$responseData.error}</p>
            {:else}
                <TeacherFinderDisplayComponent data={$responseData.matched_teachers}/>
            {/if}
        {:else}

            <p>No data yet. Submit the form to see results.</p>
        {/if}
   
    </div>


</div>
