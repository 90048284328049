<script>
    export let event;
    let sending = false;
  
    // Reuse the getCookie function to fetch the CSRF token
    function getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    }
  
    async function sendClassInfoToEmail() {
        const token = localStorage.getItem('jwtToken'); // Retrieve token from localStorage
        console.log("This is the token retrieved in fetchEvents:")
        console.log(token)
        try {
            console.log("This is the token used within the function:")
            console.log(token)
            sending = true;

            // Get the CSRF token using getCookie function
            const csrfToken = getCookie('csrftoken');

            const response = await fetch(`http://192.168.32.131:8000/studentplayground/studentList/sendClassInfoToEmail/${event.id}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,  // CSRF token (if necessary)
                'Authorization': `Bearer ${token}`  // Include JWT token in Authorization header
            },
            credentials: 'include',  // Optionally include credentials if using cookies
            });

            const data = await response.json();
            if (response.ok) {
            alert(data.message);  // Show success message
            } else {
            alert('Error: ' + data.message);  // Show error message
            }
        } catch (error) {
            alert('An error occurred: ' + error);  // Handle network or server errors
        } finally {
            sending = false;  // Stop loading indicator
        }
        }

  
    // Handle click and prevent default behavior
    function handleClick(event) {
      event.preventDefault();
      sendClassInfoToEmail();
    }
  </script>
  
  <!-- Button to send email -->
  <div class="sendClassInfoToEmail">
    <button class="emailButton" on:click={handleClick}>
      {sending ? "Siunčiama..." : "Gauti pamokos informaciją el.paštu"}
    </button>
  </div>
  
  <!-- Styling to make button look like a link -->
  <style>
    .emailButton {
      background: none;
      border: none;
      color: rgb(0, 0, 0);
      text-decoration: underline;
      cursor: pointer;
      padding: 0;
      font-weight:700;
    }
    .sendClassInfoToEmail {
      margin-top: 10px;
    }
  </style>
  