<script>
   import IconButton from './ButtonComponent.svelte';

  </script>
  
  <style>
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%;

    }
  
    
    .top-bar {
        width: 100%;
        text-align: center;
        border-bottom: 2px solid #ccc;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        }
            


    .content {
      margin-top: 50px; /* Ensures the content is not under the top bar */
      color: #000;
      display:flex;
      justify-content: space-around;

    }
    
    .illustration {
      margin-top: 20px;
    }
    .filler{
        width:60px;
    }
    .label{
        font-size:23px;
    }
    .illustration img {
      width: 100%;
      height: auto;
      max-height:500px;
    }
    .exit-button{
        display:flex;
        justify-content: flex-end;
    }
    .exit-button img{
        width:80%;
        max-width:60px;
    }
  </style>
  
  <div class="container">
    <div class="top-bar">
        <div class="filler"></div>
        <div class='label'>Registruotis pamokai</div>
      <a href='#/login-greeting' class='exit-button'>
        <img src='/static/icons/exitButtonIcon.png' alt="" class="icon" />
    </a>
    </div>
    <div class="content">
        <div class="illustration">
          <img src="/static/icons/registrationSucess.png" alt="Illustration">
        </div>
        
      </div>
    
  </div>
  