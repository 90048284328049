<script>
    export let teacher;
    export let label ='Mokytoja/as';
  </script>
  
  <style>
    .teacher-name {
      font-size: 1rem;
      color: #000000;
      margin-bottom: 1rem;
    }
  </style>
  
  <div class="teacher-name">
    {label}: {teacher}
  </div>