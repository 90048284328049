<script>

  import { onMount } from 'svelte';
  import Editor from '@tinymce/tinymce-svelte';

  let studentName = '';
  let startDate = '';
  let endDate = '';
  let findAllClasses = false;
  let classes = [];
  let errorMessage = '';
  let successMessage = 'Send';
  let selectedClasses = new Set();
  let selectedClassesDetails = [];
  let emailScript = '';
  let emailSubject = '';
  let stud_Email = '';
  let isSendingEmail = false;
  let isSubmitting = false;

  // Variable for TinyMCE editor content
  let emailContent = '';

  const tinyMceApiKey = 'usvl1m5wsho8d78ixib2ladkg9cca2oonqln4so3wls7ujj5';
    function adjustDates() {
      const now = new Date();
      const oneWeekAgo = new Date(now);
      oneWeekAgo.setDate(now.getDate() - 7);
      const oneMonthAhead = new Date(now);
      oneMonthAhead.setMonth(now.getMonth() + 1);
  
      // Format dates to match 'datetime-local' input requirements
      const pad = (n) => n.toString().padStart(2, '0');
      const formatDate = (date) => {
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
      };
  
      startDate = formatDate(oneWeekAgo);
      endDate = formatDate(oneMonthAhead);
    }
  
    // Watch for changes to findAllClasses checkbox
    $: if (findAllClasses) {
      adjustDates();
    } else {
      // Clear dates when checkbox is unchecked
      startDate = '';
      endDate = '';
    }


  // Function to submit the form
  async function submitForm() {
    isSubmitting = true;
    errorMessage = '';
    classes = [];
    selectedClasses.clear();
    selectedClassesDetails = [];
    emailScript = '';
    stud_Email = '';

    // Retrieve JWT token from localStorage
    const token = localStorage.getItem('jwtToken');
    console.log("JWT Token:", token);

    const data = {
        studentName,
        startDate,
        endDate,
        findAllClasses, // Include this if your backend expects it
    };

    try {
        const response = await fetch('https://www.tutory.app/studentplayground/get-classes-for-email/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Unknown error');
        }

        const result = await response.json();
        classes = result.classes;


        if (classes.length === 0) {
            errorMessage = 'No classes found for the given criteria.';
        }
    } catch (error) {
        errorMessage = `An error occurred: ${error.message}`;
    } finally {
        isSubmitting = false;
    }
  }

  function toggleClassSelection(classId) {
    if (selectedClasses.has(classId)) {
      selectedClasses.delete(classId);
    } else {
      selectedClasses.add(classId);
    }
  }
  
    async function generateSelectedClassesInfo() {
        const selectedClassIds = Array.from(selectedClasses);
        console.log('Selected Class IDs:', selectedClassIds);

        if (selectedClassIds.length === 0) {
        errorMessage = 'Please select at least one class.';
        return;
        }

        // Retrieve JWT token from localStorage
        const token = localStorage.getItem('jwtToken');

        try {
        const response = await fetch('https://www.tutory.app/studentplayground/generate_email_script/', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ classIds: selectedClassIds }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Unknown error');
        }

        const result = await response.json();
        emailScript = result.emailScript;
        emailSubject = result.emailSubject;
        stud_Email = result.student_email;

        // Initialize the email content with the generated script
        emailContent = emailScript;

        console.log(stud_Email)
        } catch (error) {
        errorMessage = `An error occurred: ${error.message}`;
        }
    }
    async function sendEmail() {
        errorMessage = '';
        successMessage = '';
        isSendingEmail = true; // Start sending email, change button color

        // Retrieve JWT token from localStorage
        const token = localStorage.getItem('jwtToken');

        const data = {
            studentName,
            emailScript: emailContent,
            emailSubject,
            stud_Email,
        };

        try {
            const response = await fetch('https://www.tutory.app/studentplayground/sendClassInfoAdmin/', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Unknown error');
            }

            const result = await response.json();
            // Handle success (e.g., show a confirmation message)
            console.log(result);
            successMessage = 'Email successfully sent.';
            } catch (error) {
            // Handle error (e.g., show an error message)
            errorMessage = 'Problem with sending the email.';
            } finally {
            isSendingEmail = false; // Reset button color
            }
        
    }
</script>
  <span>EMAILU SIUNTINETOJAS</span>
  <form on:submit|preventDefault={submitForm}>
    <label>
      Student Name:
      <input type="text" bind:value={studentName} required />
    </label>
    <br />
  
    <label>
      Start Date:
      <input
        type="datetime-local"
        bind:value={startDate}
        required
        disabled={findAllClasses}
      />
    </label>
    <br />
  
    <label>
      End Date:
      <input
        type="datetime-local"
        bind:value={endDate}
        required
        disabled={findAllClasses}
      />
    </label>
    <br />
  
    <label>
      <input type="checkbox" bind:checked={findAllClasses} />
      Find all classes
    </label>
    <br />
  
    <button
        type="submit"
        disabled={isSubmitting}
        class:loading={isSubmitting}
    >
        {#if isSubmitting}
            Searching...
        {:else}
            Search Classes
        {/if}
    </button>
  </form>
  <!-- Display Error Message -->
  {#if errorMessage}
  <p class="error">{errorMessage}</p>
  {/if}


  {#if classes.length > 0}
    <h2>Classes Found:</h2>
    <ul>
      {#each classes as clas}
        <li>
          <label>
            <input type="checkbox" on:change={() => toggleClassSelection(clas.id)} />
            <strong>{clas.name}</strong> with {clas.teacher} on {clas.displayTime}
          </label>
        </li>
      {/each}
    </ul>
    <button on:click={generateSelectedClassesInfo}>Generate</button>
  {/if}
  
  {#if selectedClassesDetails.length > 0}
    <h2>Selected Classes:</h2>
    <ul>
      {#each selectedClassesDetails as clas}
        <li>
          <strong>{clas.name}</strong> with {clas.teacher} on {clas.displayTime}
        </li>
      {/each}
    </ul>
  {/if}
 <!-- Display email preview and send email button -->
 {#if emailScript}
  <h2>Edit Email Content:</h2>
  <Editor
    bind:value={emailContent}
    apiKey={tinyMceApiKey}
    init={{
      height: 500,
      menubar: true,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
        // Include any additional plugins you need
      ],
      toolbar:
        'undo redo | formatselect | bold italic underline | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | code | help',
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    }}
  />
  <!-- Editable Email Field -->
  <div >
    <label>
      Email will be sent to:
      <input class='text_input' type="email" bind:value={stud_Email} required />
    </label>
  </div>

  <!-- Editable Subject Field -->
  <div >
    <label>
      Subject:
      <input class='text_input' type="text" bind:value={emailSubject} required />
    </label>
  </div>
  <button
    class:email-sending={isSendingEmail}
    on:click={sendEmail}
    disabled={isSendingEmail}
  >
    {#if isSendingEmail}
      Sending...
    {:else}
      Send Email
    {/if}
  </button>
  <!-- Display success message -->
  {#if successMessage}
    <p class="success">{successMessage}</p>
  {/if}
{/if}

<style>
 /* Base button styles */
 button {
   padding: 0.5rem 1rem;
   font-size: 1rem;
   background-color: #fab45a;
   color: black;
   border: none;
   border-radius: 4px;
   cursor: pointer;
   transition: background-color 0.3s ease;
   margin-top: 0.5rem;
 }

 /* Disabled button styles */
 button:disabled {
   background-color: #6c757d;
   cursor: not-allowed;
 }

 /* Error message styling */
 .error {
   color: red;
   margin-top: 0.5rem;
 }

 /* Success message styling */
 .success {
   color: green;
   margin-top: 0.5rem;
 }

 /* Input disabled styles */
 input:disabled {
   background-color: #e9ecef;
 }
 .text_input{
    width:100%;
  }


</style>