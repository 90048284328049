<script>
  export let rating;
  export let maxRating = 5;
  export let onRate;
  
  function handleKeydown(event, value) {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      onRate(value);
    }
  }
  
  function handleClick(value) {
    onRate(value);
  }
</script>

<div class="star-rating">
  {#each Array(maxRating) as _, index}
    <span 
      class="fa fa-star {index < rating ? 'checked' : ''}" 
      role="button"                  
      tabindex="0"                   
      on:click={() => handleClick(index + 1)}
      on:keydown={(event) => handleKeydown(event, index + 1)} 
      aria-label="Rate {index + 1} stars" 
    ></span>
  {/each}
</div>

<style>
  .fa-star {
    font-size: 24px;
    cursor: pointer;
    color: #ddd;
  }
  .fa-star.checked {
    color: #14695b;
  }
</style>
