<script>
    let message = '';
    let loading = false;
    let errorMessage = '';
  
    // Function to call the Django API
    async function refreshDatabase() {
      const url = 'https://www.tutory.app/studentplayground/refresh/';
      const token = localStorage.getItem('jwtToken'); 
      if (!token) {
            throw new Error('JWT token not found. Please log in.');
        }
  
      loading = true;
      message = '';
      errorMessage = '';
  
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
  
        const data = await response.json();
        message = data.message; // Assuming the response is { "message": "Successfully updated baby" }
      } catch (error) {
        errorMessage = `Failed to update: ${error.message}`;
      } finally {
        loading = false;
      }
    }
  </script>
  
  <!-- HTML/Markup -->
  <div>
    <button on:click={refreshDatabase} disabled={loading}>
      {loading ? 'Updating...' : 'Refresh Database'}
    </button>
  
    <!-- Display success message -->
    {#if message}
      <p>{message}</p>
    {/if}
  
    <!-- Display error message -->
    {#if errorMessage}
      <p style="color: red;">{errorMessage}</p>
    {/if}
  </div>
  
  <style>
    button {
      padding: 10px 20px;
      background-color: #4CAF50;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    button:disabled {
      background-color: #cccccc;
    }
    p {
      margin-top: 10px;
    }
  </style>
  